import { Uploader } from '@/components/Widgets';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Card, Button, CheckBox } from '@/components/UI';
import useLocation from '@/compositions/useLocation';
export default defineComponent({
    name: 'SettingsTab',
    components: {
        Uploader,
        Card,
        Button,
        CheckBox
    },
    setup: () => ({
        toast: useToast(),
        ...useLocation()
    }),
    data: () => ({
        userCopy: { _id: '', username: '' },
        renameFile: '',
        timestamp: 0,
        loading: false,
        updateTimeout: null
    }),
    computed: {
        user() {
            return this.$store.direct.state.auth.user;
        },
        target() {
            return `https://${this.currentLocation.apiurl}/api/files/${this.renameFile}`;
        },
        imageurl() {
            return `https://${this.currentLocation.apiurl}/media/${this.userCopy.displayimage}?timestamp=${this.timestamp}`;
        }
    },
    watch: {
        user(newValue) {
            this.timestamp = (new Date()).getTime();
            this.userCopy = JSON.parse(JSON.stringify(newValue));
            if (this.userCopy.notifications === undefined) {
                this.userCopy.notifications = { systemfailure: false, gamestarts: false };
            }
        }
    },
    mounted() {
        this.renameFile = 'user_display_image_' + Math.random().toString(36).slice(7) + '.jpg';
        this.$store.direct.dispatch.auth.get();
    },
    methods: {
        checkFile(response) {
            if (response.code === 200) {
                this.userCopy.displayimage = this.renameFile; // TOMC: REACTIVE
                this.renameFile = 'user_display_image_' + Math.random().toString(36).slice(7) + '.jpg';
                this.loading = false;
            }
            else {
                this.loading = true;
            }
        },
        async save() {
            try {
                await this.$store.direct.dispatch.auth.update(this.userCopy);
                this.toast.success('User profile saved');
            }
            catch (error) {
                this.toast.error(`Could not save user ${error}`);
            }
        }
    }
});
